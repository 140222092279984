<template>
        <risk-explorer></risk-explorer>
</template>
<script>
    import riskExplorer from './RiskExplorer.vue'
    export default{
        name:"riskNav",
        components: {
            riskExplorer
        },
    }
</script>
<style scoped>
</style>